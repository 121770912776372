<template>
  <div class="project-empty d-flex align-center justify-center py-6">
    <div class="project-empty__container d-flex flex-column align-center">
      <img
        class="project-empty__illustration mb-6"
        :width="175"
        :src="$static.illustration"
        :alt="$t('projectEmptyContent.createPermissions.title')">

      <template v-if="hasCreatePermissions">
        <h3 class="mb-1">
          {{ $t('projectEmptyContent.createPermissions.title') }}
        </h3>
        <p class="mb-0">
          {{ $t('projectEmptyContent.createPermissions.p') }}
        </p>
        <CyButton
          icon="add"
          theme="secondary"
          variant="primary"
          class="my-6"
          :to="{ name: 'addServiceEnv' }">
          {{ $t('createEnvironment') }}
        </CyButton>
        <p class="mb-8">
          <a
            :href="$docLinks.project.environments"
            class="cy-link"
            target="_blank">
            {{ $t('learnAboutEnvironments') }}
            <v-icon small>
              open_in_new
            </v-icon>
          </a>
        </p>
      </template>

      <template v-else>
        <h3 class="mb-1">
          {{ $t('projectEmptyContent.createPermissions.title') }}
        </h3>

        <p class="mb-0">
          {{ $t('projectEmptyContent.noCreatePermissions.p') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { environments } from '@/assets/images'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageProjectEmpty',
  breadcrumb () {
    const { projectName } = this
    const { projectCanonical } = this.$route.params
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectEnvironments'), [
      {
        label: projectName,
        name: 'projectEmpty',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },

    ])
  },
  computed: {
    $static: () => ({
      illustration: environments.empty,
    }),
    ...mapGetters('organization/project', [
      'hasEnvs',
    ]),
    hasCreatePermissions () {
      return this.$cycloid.permissions.canDisplay('UpdateProject')
    },
  },
  mounted () {
    if (this.hasEnvs) this.$router.push({ name: 'projectEnvironments' })
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectEnvironments',
        createEnvironment: 'Create @:Environment',
        projectEmptyContent: {
          createPermissions: {
            p: 'Environments are instances of a stack, configured using variables you define.',
            title: 'No environments in your project',
          },
          noCreatePermissions: {
            p: 'You do not have sufficient permissions to create an environment.',
          },
        },
        learnAboutEnvironments: 'Learn about environments',
      },
      es: {
        title: '@:routes.projectEnvironments',
        createEnvironment: 'Crear @:Environment',
        projectEmptyContent: {
          createPermissions: {
            p: 'Los entornos son instancias de una stack, configurados utilizando variables que usted define.',
            title: 'No hay entornos en su proyecto',
          },
          noCreatePermissions: {
            p: 'No tiene permisos suficientes para crear un entorno.',
          },
        },
        learnAboutEnvironments: 'Aprenda sobre entornos',
      },
      fr: {
        title: '@:routes.projectEnvironments',
        createEnvironment: 'Créer un @:Environment',
        projectEmptyContent: {
          createPermissions: {
            p: `Les environnements sont des instances d'une stack, configurés à l'aide de variables que vous définissez.`,
            title: 'Aucun environnement dans votre projet',
          },
          noCreatePermissions: {
            p: `Vous n'avez pas les autorisations suffisantes pour créer un environnement.`,
          },
        },
        learnAboutEnvironments: 'En savoir plus sur les environnements',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.project-empty {
  &__container {
    max-width: 500px;

    h3,
    p {
      text-align: center;
    }

    .cy-btn {
      margin-right: auto;
      margin-left: auto;
    }

    .cy-link {
      .v-icon {
        color: cy-get-color("grey", "dark-1");
      }
    }
  }

  &__illustration {
    margin-top: 128px;
  }
}
</style>
